@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

:root{
  --color-primary : #303838;
  --color-primary-variant : #1f2a2a;
  --color-secondry : #FDE74C;
  --color-light : rbga(0,0,0,0.3);
  --color-white : white;
  --transition : 0.3s linear;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --tv-flicker-color: rgba(200,235,255,0.4);
  --tv-flicker-color-2: rgba(200,220,255,0.6);

}
html{
  scroll-behavior: smooth;
}
.container{
  padding: 4rem;
  text-align: center;
  max-width: 1440px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}
.container > p{
  font-weight: 300;
  color: var(--color-light);
  text-transform: capitalize;
}
.container h2{
  font-size: 2rem;
  color: var(--color-secondry);
}
body{
  background-color: #0a0a0a;
  /* background-image: url("../src/assets/bg.png"),repeating-linear-gradient(
        to right, transparent 0 500px, #73814B33 500px 501px
    ); */
    
  color: var(--color-white);
}

.btn{
  background-color: transparent;
  border: 1px solid var(--color-secondry);
  color: var(--color-secondry);
  padding: 0.8rem 1.3rem;
  border-radius: 8px;
  transition: var(--transition);
  cursor: pointer;
}
.btn__primary{
  background-color: var(--color-secondry);
  color: var(--color-primary);
}

.btn:hover{
  background-color: var(--color-white);
  color: var(--color-primary);
  border: none;
  letter-spacing: 1px;
}

img{
  display: inline !important;
}


#container {
  /* max-width: 1024px; */
  max-width: 100vw;
  margin: auto;
}

#monitor {
	background: #000; 
	position: relative;
	border-top: 3px solid #888; 
	/* margin: 5%; */
	padding: 2% 2% 4% 2%; 
	border-radius: 10px; 
	border-bottom-left-radius: 50% 2%; 
	border-bottom-right-radius: 50% 2%; 
	transition: margin-right 1s;
}

#monitor:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 3%;
	left: 36%;
	height: .5%; 
	width: 28%;
	background: #ddd; 
	border-radius: 50%; 
	box-shadow: 0 0 3px 0 white; 
}

.scrollbar::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(55 65 81);
  outline: 1px solid rgb(55 65 81);
  border-radius: 8px;
}
#monitorscreen {
	position: relative;
  background-color: #111;
	background-size: cover; 
	background-position: top center;
	height: 0; 
	padding-bottom: 56.25%; 
	position: relative;
	overflow: hidden;
  flex-grow: 1;
}
.font-mono-imp{
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.z-index-10{
  z-index: 10;
}
.wrapper-button:hover{
  visibility: visible;
}


.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.skeleton {
  background-color: #e5e7eb; /* Light gray for the skeleton */
  border-radius: 0.5rem;
  animation: pulse 1.5s infinite ease-in-out alternate;
}

.skeleton-image {
  width: 100%;
  height: 18rem;
}

.skeleton-text {
  height: 20px;
}

.skeleton-title {
  width: 60%;
}

.skeleton-details {
  width: 90%;
  height: 50px;
}

.skeleton-buttons {
  display: flex;
  gap: 1rem;
}

.skeleton-button {
  width: 100px;
  height: 40px;
}

@keyframes pulse {
  0% {
    background-color: #818181;
  }
  50% {
    background-color: #6e6e6e;
  }
  100% {
    background-color: #909091;
  }
}


@media all and (min-width: 960px) {
	#monitor {
		-webkit-animation: tvflicker .2s infinite alternate; 
		-moz-animation:    tvflicker .5s infinite alternate; 
		-o-animation:      tvflicker .5s infinite alternate; 
		animation:         tvflicker .5s infinite alternate; 
	}

	@-webkit-keyframes tvflicker {
	  0%   { box-shadow: 0 0 100px 0 var(--tv-flicker-color); }
	  100% { box-shadow: 0 0 95px 0 var(--tv-flicker-color-2); }
	}
	@-moz-keyframes tvflicker {
	  0%   { box-shadow: 0 0 100px 0 var(--tv-flicker-color); }
	  100% { box-shadow: 0 0 60px 0 var(--tv-flicker-color-2); }
	}
	@-o-keyframes tvflicker {
	  0%   { box-shadow: 0 0 100px 0 var(--tv-flicker-color); }
	  100% { box-shadow: 0 0 60px 0 var(--tv-flicker-color-2); }
	}
	@keyframes tvflicker {
	  0%   { box-shadow: 0 0 100px 0 var(--tv-flicker-color); }
	  100% { box-shadow: 0 0 60px 0 var(--tv-flicker-color-2); }
	}
}



/* ==================MEDIA QURIES (MEDIUM DEVICES)=============== */

@media screen and (max-width:1024px){
  .container{
      padding: 3rem;
  }
}

/* ==================MEDIA QURIES (SMALL DEVICES)=============== */

@media screen and (max-width:600px) {
  .container {
    padding: 1rem;
  }
}

@media screen and (max-width:480px) {
  #container {
    transform: rotate(90deg);
    width: 100vh;
    max-width: 60vh;
    translate: -21% 50%;
  }
}