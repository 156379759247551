body::-webkit-scrollbar{
    display: none;
  }
.home__container{
 padding-top: 3rem;   
}
.home__container h2 img{
    width: 2rem;
    position: relative;
    bottom: 1.8rem;
    left: 0.8rem;
    animation: rotateCrown 2s infinite;
}
.grid-bg {
    position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #80808012 1px, transparent 1px),
    linear-gradient(to bottom, #80808012 1px, transparent 1px);
  background-size: 24px 24px;
  }
@keyframes rotateCrown {
    0%{
        transform: rotate(0deg);
        bottom : 1.8rem;
    }60%{
        transform: rotate(360deg);
        bottom : 3rem;
    }100%{
        transform: rotate(360deg);
        bottom : 2rem;
    }
}
.btn__container{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;
}
.img__container{
    margin-top: 3rem;
    /* background-color: var(--color-secondry); */
    width: 20rem;
    border-radius: 16px 16px 0 0;
    padding-top: 1rem;
    position: relative;
    transition: var(--transition);
    bottom: 0;
}
.img__container img{
    -mox-filter: drop-shadow(3px 3px 20px #fde74c);
    -webkit-filter: drop-shadow(3px 3px 20px #fde74c);
    filter: drop-shadow(3px 3px 20px #fde74c);
}
/* .img__container:hover{
    bottom: 1rem;
} */
.img__container img{
    width: 100%;
    object-fit: cover;
}

.home__content__container{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.side__bar{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    color: var(--color-secondry);
    position: absolute;
}
.social__container__home{
    left: 1.5rem;
}
.side__bar::after{
    content: '';
    background-color: var(--color-secondry);
    width: 2px;
    height: 3rem;
    position: absolute;
    bottom: -4rem;
    left: 50%;
    transform: translateX(-50%);
}
.social__container__home a i{
    color: var(--color-secondry);
    cursor: pointer;
    transition: var(--transition);
}
.social__container__home a i:hover{
    color: var(--color-white);
}

.scroll__container{
    right: 1rem;
}

.scroll__container p{
    transform: rotate(90deg);
    transition: var(--transition);
    cursor: pointer;
}
.scroll__container::after{
    bottom: -6rem;
}
.scroll__container p:hover{
    color: white;
}
.cls-1{fill:#fff;}

.pop-animation{
    animation: pop 1s ease infinite alternate;
}

@keyframes pop {
    0%{
        transform: scale(1);
    }100%{
        transform: scale(1.04);
    }
}

/* ==================MEDIA QURIES (MEDIUM DEVICES)=============== */

@media screen and (max-width:1024px){
    
}

/* ==================MEDIA QURIES (SMALL DEVICES)=============== */

@media screen and (max-width:600px) {
    .side__bar{
        display: none;
    }
    .home__container{
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}
